export default function BackIcon({
  className,
}: {
  readonly className: string;
}) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 29 34'
    >
      <path d='M13.7151 0.598955C14.0828 0.29927 14.5484 0.146217 15.0223 0.169262C15.4961 0.192307 15.9447 0.38982 16.2816 0.723778C16.6186 1.05774 16.8201 1.50453 16.8473 1.97815C16.8746 2.45176 16.7257 2.91872 16.4293 3.28913L14.5558 5.16264C17.341 5.2382 20.0422 6.13312 22.3215 7.73552C24.6009 9.33792 26.3574 11.5767 27.3713 14.1719C28.3852 16.7671 28.6116 19.6037 28.0221 22.3269C27.4327 25.0501 26.0536 27.5391 24.0572 29.4827C22.0609 31.4264 19.5358 32.7384 16.7978 33.2548C14.0599 33.7712 11.2304 33.469 8.66322 32.386C6.09604 31.3031 3.90506 29.4873 2.36421 27.1659C0.823368 24.8445 0.00102473 22.1203 0 19.3341C0 18.8245 0.202449 18.3357 0.562809 17.9753C0.92317 17.615 1.41192 17.4125 1.92155 17.4125C2.43118 17.4125 2.91993 17.615 3.28029 17.9753C3.64065 18.3357 3.8431 18.8245 3.8431 19.3341C3.84319 21.3809 4.45144 23.3816 5.59064 25.0821C6.72984 26.7826 8.34861 28.1063 10.2415 28.8851C12.1343 29.6639 14.2159 29.8627 16.222 29.4564C18.2281 29.05 20.0682 28.0567 21.5087 26.6027C22.9493 25.1486 23.9253 23.2993 24.3129 21.2895C24.7005 19.2797 24.4822 17.2001 23.6857 15.3146C22.8893 13.4291 21.5505 11.8227 19.8394 10.6995C18.1283 9.57623 16.1221 8.9867 14.0754 9.00574L16.4293 11.3596L16.4773 11.4077C16.8039 11.7788 16.975 12.2614 16.9549 12.7554C16.9348 13.2493 16.7251 13.7165 16.3694 14.0598C16.0137 14.4032 15.5394 14.5962 15.045 14.5988C14.5507 14.6014 14.0744 14.4134 13.7151 14.0738L8.43081 8.81359L8.40679 8.76555C8.22869 8.57255 8.09205 8.34509 8.00529 8.09722C7.91854 7.84935 7.88351 7.58632 7.90238 7.32439C7.88299 7.05113 7.92225 6.77688 8.01753 6.52003C8.11281 6.26319 8.26191 6.02969 8.45483 5.83518L13.7151 0.598955Z' />
    </svg>
  );
}
