export default function SuperLikeIcon({
  className,
}: {
  readonly className: string;
}) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 33 30'
    >
      <path d='M14.7808 1.04358L10.9331 8.80044L2.32448 10.0483C0.780697 10.271 0.162005 12.1633 1.28154 13.2472L7.5097 19.2816L6.03663 27.8059C5.77147 29.3467 7.40364 30.5009 8.77065 29.7803L16.4719 25.7554L24.1731 29.7803C25.5401 30.495 27.1723 29.3467 26.9071 27.8059L25.4341 19.2816L31.6622 13.2472C32.7818 12.1633 32.1631 10.271 30.6193 10.0483L22.0106 8.80044L18.163 1.04358C17.4736 -0.339059 15.4761 -0.356635 14.7808 1.04358Z' />
    </svg>
  );
}
