export default function UserIcon({
  className,
}: {
  readonly className: string;
}) {
  return (
    <svg
      className={className}
      width='22'
      height='26'
      viewBox='0 0 22 26'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M10.938 12.6667C14.3901 12.6667 17.188 9.8689 17.188 6.41675C17.188 2.9646 14.3901 0.166748 10.938 0.166748C7.48584 0.166748 4.68799 2.9646 4.68799 6.41675C4.68799 9.8689 7.48584 12.6667 10.938 12.6667ZM15.313 14.2292H14.4976C13.4136 14.7273 12.2075 15.0105 10.938 15.0105C9.66846 15.0105 8.46729 14.7273 7.37842 14.2292H6.56299C2.93994 14.2292 0.000488281 17.1687 0.000488281 20.7917V22.823C0.000488281 24.1169 1.05029 25.1667 2.34424 25.1667H19.5317C20.8257 25.1667 21.8755 24.1169 21.8755 22.823V20.7917C21.8755 17.1687 18.936 14.2292 15.313 14.2292Z' />
    </svg>
  );
}
