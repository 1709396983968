export default function MessageIcon({
  className,
}: {
  readonly className: string;
}) {
  return (
    <svg
      className={className}
      width='15'
      height='15'
      viewBox='0 0 15 15'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M11.2556 0H3.75188C1.68084 0 0 1.67334 0 3.73687V8.22411V8.97449C0 11.038 1.68084 12.7114 3.75188 12.7114H4.87744C5.08004 12.7114 5.35017 12.8464 5.47774 13.0115L6.6033 14.5048C7.09855 15.1651 7.90895 15.1651 8.4042 14.5048L9.52976 13.0115C9.67234 12.8239 9.89745 12.7114 10.1301 12.7114H11.2556C13.3267 12.7114 15.0075 11.038 15.0075 8.97449V3.73687C15.0075 1.67334 13.3267 0 11.2556 0ZM4.50225 7.50375C4.08204 7.50375 3.75188 7.16608 3.75188 6.75338C3.75188 6.34067 4.08954 6.003 4.50225 6.003C4.91496 6.003 5.25263 6.34067 5.25263 6.75338C5.25263 7.16608 4.92246 7.50375 4.50225 7.50375ZM7.50375 7.50375C7.08354 7.50375 6.75338 7.16608 6.75338 6.75338C6.75338 6.34067 7.09105 6.003 7.50375 6.003C7.91646 6.003 8.25413 6.34067 8.25413 6.75338C8.25413 7.16608 7.92396 7.50375 7.50375 7.50375ZM10.5053 7.50375C10.085 7.50375 9.75488 7.16608 9.75488 6.75338C9.75488 6.34067 10.0925 6.003 10.5053 6.003C10.918 6.003 11.2556 6.34067 11.2556 6.75338C11.2556 7.16608 10.9255 7.50375 10.5053 7.50375Z' />
    </svg>
  );
}
