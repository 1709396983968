export default function NextIcon({
  className,
}: {
  readonly className: string;
}) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 30 30'
    >
      <path d='M18.4896 14.5813L28.9324 4.16276C29.3897 3.70546 29.6466 3.08522 29.6466 2.43849C29.6466 1.79176 29.3897 1.17152 28.9324 0.714218C28.4751 0.256912 27.8549 0 27.2081 0C26.5614 0 25.9412 0.256912 25.4839 0.714218L15.0654 11.157L4.64689 0.714218C4.18958 0.256912 3.56934 -4.8185e-09 2.92261 0C2.27589 4.8185e-09 1.65565 0.256912 1.19834 0.714218C0.741037 1.17152 0.484125 1.79176 0.484125 2.43849C0.484125 3.08522 0.741037 3.70546 1.19834 4.16276L11.6411 14.5813L1.19834 24.9998C0.970718 25.2255 0.790048 25.4941 0.666754 25.7901C0.543459 26.086 0.47998 26.4034 0.47998 26.724C0.47998 27.0446 0.543459 27.3621 0.666754 27.658C0.790048 27.9539 0.970718 28.2225 1.19834 28.4483C1.42411 28.6759 1.69271 28.8566 1.98865 28.9799C2.28459 29.1032 2.60202 29.1667 2.92261 29.1667C3.24321 29.1667 3.56064 29.1032 3.85658 28.9799C4.15252 28.8566 4.42112 28.6759 4.64689 28.4483L15.0654 18.0055L25.4839 28.4483C25.7096 28.6759 25.9782 28.8566 26.2742 28.9799C26.5701 29.1032 26.8875 29.1667 27.2081 29.1667C27.5287 29.1667 27.8462 29.1032 28.1421 28.9799C28.438 28.8566 28.7066 28.6759 28.9324 28.4483C29.16 28.2225 29.3407 27.9539 29.464 27.658C29.5873 27.3621 29.6508 27.0446 29.6508 26.724C29.6508 26.4034 29.5873 26.086 29.464 25.7901C29.3407 25.4941 29.16 25.2255 28.9324 24.9998L18.4896 14.5813Z' />
    </svg>
  );
}
