export default function LikeIcon({
  className,
}: {
  readonly className: string;
}) {
  return (
    <svg
      className={className}
      viewBox='0 0 30 26'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M14.7087 25.833C14.5152 25.8341 14.3233 25.797 14.1441 25.7238C13.965 25.6506 13.802 25.5428 13.6646 25.4065L2.23781 13.965C0.804478 12.5167 0.000488281 10.5613 0.000488281 8.5237C0.000488281 6.48604 0.804478 4.53068 2.23781 3.08237C3.68235 1.64191 5.63912 0.833008 7.67913 0.833008C9.71914 0.833008 11.6759 1.64191 13.1205 3.08237L14.7087 4.67065L16.297 3.08237C17.7415 1.64191 19.6983 0.833008 21.7383 0.833008C23.7783 0.833008 25.7351 1.64191 27.1797 3.08237C28.613 4.53068 29.417 6.48604 29.417 8.5237C29.417 10.5613 28.613 12.5167 27.1797 13.965L15.7529 25.4065C15.6155 25.5428 15.4525 25.6506 15.2733 25.7238C15.0941 25.797 14.9023 25.8341 14.7087 25.833Z' />
    </svg>
  );
}
