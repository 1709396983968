export default function BookIcon({
  className,
}: {
  readonly className?: string;
}) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 18 20'
    >
      <path d='M16.6667 0H3.33333C2.44928 0 1.60143 0.35119 0.976311 0.976311C0.35119 1.60143 0 2.44928 0 3.33333V16.6667C0 17.5507 0.35119 18.3986 0.976311 19.0237C1.60143 19.6488 2.44928 20 3.33333 20H16.6667C16.9614 20 17.244 19.8829 17.4523 19.6746C17.6607 19.4662 17.7778 19.1836 17.7778 18.8889V1.11111C17.7778 0.816426 17.6607 0.533811 17.4523 0.325437C17.244 0.117063 16.9614 0 16.6667 0ZM3.33333 17.7778C3.03865 17.7778 2.75603 17.6607 2.54766 17.4523C2.33929 17.244 2.22222 16.9614 2.22222 16.6667C2.22222 16.372 2.33929 16.0894 2.54766 15.881C2.75603 15.6726 3.03865 15.5556 3.33333 15.5556H15.5556V17.7778H3.33333Z' />
    </svg>
  );
}
