export default function CardIcon({
  className,
}: {
  readonly className?: string;
}) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 30 40'
    >
      <path d='M26.25 0H3.75C1.67969 0 0 1.67969 0 3.75V36.25C0 38.3203 1.67969 40 3.75 40H26.25C28.3203 40 30 38.3203 30 36.25V3.75C30 1.67969 28.3203 0 26.25 0ZM11.25 2.5H18.75C19.4375 2.5 20 3.0625 20 3.75C20 4.4375 19.4375 5 18.75 5H11.25C10.5625 5 10 4.4375 10 3.75C10 3.0625 10.5625 2.5 11.25 2.5ZM15 12.5C17.7578 12.5 20 14.7422 20 17.5C20 20.2578 17.7578 22.5 15 22.5C12.2422 22.5 10 20.2578 10 17.5C10 14.7422 12.2422 12.5 15 12.5ZM23.75 31C23.75 31.8281 22.9688 32.5 22 32.5H8C7.03125 32.5 6.25 31.8281 6.25 31V29.5C6.25 27.0156 8.60156 25 11.5 25H11.8906C12.8516 25.3984 13.8984 25.625 15 25.625C16.1016 25.625 17.1562 25.3984 18.1094 25H18.5C21.3984 25 23.75 27.0156 23.75 29.5V31Z' />
    </svg>
  );
}
