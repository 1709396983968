export default function EyeCloseIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='19'
      viewBox='0 0 190 152'
      className='fill-light'
    >
      <path d='M94.6331 118.293C72.202 118.293 54.0441 100.931 52.3732 78.9283L21.3511 54.9534C17.273 60.0696 13.5202 65.4785 10.4919 71.3931C9.81537 72.7313 9.4629 74.2098 9.4629 75.7093C9.4629 77.2088 9.81537 78.6873 10.4919 80.0255C26.5293 111.317 58.279 132.488 94.6331 132.488C102.591 132.488 110.268 131.305 117.668 129.395L102.322 117.521C99.7878 118.011 97.2142 118.269 94.6331 118.293ZM187.439 135.475L154.746 110.208C164.677 101.838 172.845 91.5776 178.774 80.0225C179.451 78.6843 179.803 77.2058 179.803 75.7063C179.803 74.2068 179.451 72.7283 178.774 71.3901C162.737 40.0989 130.987 18.9275 94.6331 18.9275C79.4074 18.946 64.429 22.779 51.0661 30.0766L13.4433 0.997388C12.9527 0.615622 12.3916 0.334273 11.7922 0.169417C11.1929 0.004561 10.5669 -0.0405699 9.95003 0.0366032C9.33319 0.113776 8.73761 0.311741 8.19731 0.619184C7.65702 0.926627 7.1826 1.33752 6.80116 1.82839L0.995972 9.3015C0.225886 10.2922 -0.119185 11.5481 0.0366532 12.7932C0.192492 14.0382 0.836478 15.1704 1.82698 15.9407L175.823 150.418C176.314 150.8 176.875 151.081 177.474 151.246C178.073 151.411 178.699 151.456 179.316 151.379C179.933 151.302 180.529 151.104 181.069 150.796C181.609 150.489 182.084 150.078 182.465 149.587L188.273 142.114C189.043 141.123 189.388 139.867 189.231 138.622C189.075 137.377 188.43 136.245 187.439 135.475ZM133.108 93.4812L121.485 84.4969C122.464 81.6682 122.983 78.7008 123.023 75.7078C123.081 71.3258 122.11 66.9914 120.189 63.0527C118.268 59.114 115.449 55.6808 111.96 53.0289C108.472 50.3769 104.409 48.5802 100.1 47.783C95.791 46.9857 91.3549 47.2103 87.1482 48.4385C88.9315 50.8549 89.8961 53.7779 89.9015 56.781C89.8573 57.7804 89.7046 58.772 89.446 59.7383L67.6773 42.9142C75.2391 36.5951 84.7786 33.1299 94.6331 33.1226C100.226 33.1195 105.765 34.2189 110.933 36.3579C116.102 38.4969 120.797 41.6336 124.752 45.5886C128.707 49.5436 131.844 54.2394 133.983 59.4075C136.122 64.5755 137.221 70.1146 137.218 75.7078C137.218 82.1045 135.654 88.0664 133.108 93.4842V93.4812Z' />
    </svg>
  );
}
