export default function TechnoIcon({
  className,
}: {
  readonly className?: string;
}) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 227 227'
    >
      <path
        d='M91.295 34.5146L113.903 11.9067M68.6872 158.858L159.119 68.4264M113.903 215.377L136.511 192.77M164.771 192.77C180.378 192.77 193.03 180.117 193.03 164.51C193.03 148.902 180.378 136.25 164.771 136.25C149.163 136.25 136.511 148.902 136.511 164.51C136.511 180.117 149.163 192.77 164.771 192.77ZM63.0352 91.0342C78.6427 91.0342 91.295 78.3819 91.295 62.7744C91.295 47.1669 78.6427 34.5146 63.0352 34.5146C47.4277 34.5146 34.7754 47.1669 34.7754 62.7744C34.7754 78.3819 47.4277 91.0342 63.0352 91.0342ZM40.4274 215.377C56.0348 215.377 68.6872 202.725 68.6872 187.118C68.6872 171.51 56.0348 158.858 40.4274 158.858C24.8199 158.858 12.1675 171.51 12.1675 187.118C12.1675 202.725 24.8199 215.377 40.4274 215.377ZM187.378 68.4264C202.986 68.4264 215.638 55.774 215.638 40.1665C215.638 24.5591 202.986 11.9067 187.378 11.9067C171.771 11.9067 159.119 24.5591 159.119 40.1665C159.119 55.774 171.771 68.4264 187.378 68.4264Z'
        strokeWidth='22.6078'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
