export default function StopIcon() {
  return (
    <svg
      className='fill-primary w-[50vw] max-w-[250px]'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 1536.4 1536.4'
    >
      <path
        d='M495.7,110.4L110.4,495.7v544.9L495.7,1426h544.9l385.3-385.3V495.7l-385.3-385.3H495.7z M1056.7,552.1v463.1
		c0,102.1-83.1,185.2-185.2,185.2H731.9c-70,0-133.2-38.7-165-101c-0.1-0.1-0.1-0.2-0.2-0.3L382.2,757.6
		c-10.1-20.8-3.4-46.1,15.8-58.9c7.4-5,16.2-7.7,25.3-7.7c16.7,0,31.6,8.5,39.9,22.9c0.1,0.2,0.2,0.4,0.4,0.6l101.6,158.8
		c3.7,5.8,10.8,8.4,17.3,6.5c6.6-1.9,11.1-8,11.1-14.8V459.5c0-25.5,20.8-46.3,46.3-46.3c12.3,0,23.9,4.8,32.7,13.5
		c8.8,8.9,13.6,20.6,13.6,32.8v339.6c0,8.5,6.9,15.4,15.4,15.4s15.4-6.9,15.4-15.4V459.5v-77.2c0-25.5,20.8-46.3,46.3-46.3
		c12.3,0,23.9,4.8,32.7,13.5c8.8,8.9,13.6,20.6,13.6,32.8v77.2v339.6c0,8.5,6.9,15.4,15.4,15.4s15.4-6.9,15.4-15.4V459.5
		c0-25.5,20.8-46.3,46.3-46.3c12.3,0,23.9,4.8,32.7,13.5c8.8,8.9,13.6,20.6,13.6,32.8v92.6v247c0,8.5,6.9,15.4,15.4,15.4
		s15.4-6.9,15.4-15.4v-247c0-25.5,20.8-46.3,46.3-46.3c12.3,0,23.9,4.8,32.7,13.5C1051.9,528.1,1056.7,539.8,1056.7,552.1z'
      />
      <path
        d='M1086.4,0H450L0,450v636.4l450,450h636.4l450-450V450L1086.4,0z M1464.5,1056.6l-407.9,407.9H479.8
		L71.8,1056.6V479.7L479.8,71.8h576.9l407.9,407.9V1056.6z'
      />
    </svg>
  );
}
