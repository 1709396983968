export default function LogoIcon({
  className,
}: {
  readonly className: string;
}) {
  return (
    <svg className={className} viewBox='0 0 41 40'>
      <path
        d='M7.74332 23.1057C7.74332 19.2954 11.9209 15.1197 11.9209 15.1197L19.3148 7.75993C18.5282 6.85519 15.8713 4.24536 10.8197 4.24536C4.70189 4.24536 -0.0175781 8.94306 -0.0175781 14.3367C-0.0175781 19.7304 3.74052 23.2971 3.74052 23.2971L9.56119 29.2649C8.80958 28.1166 7.74332 26.0114 7.74332 23.1057Z'
        fill='currentColor'
      />
      <path d='M36.5498 8.24705L29.121 0.330566C29.121 0.330566 32.1799 3.54936 32.1799 8.59503C32.1799 17.6424 18.0215 22.4271 18.0215 32.3445C18.0215 35.9982 19.7694 37.8251 21.0804 40C21.0804 40 29.6803 31.7355 36.6022 24.8282C45.2546 16.2157 36.5498 8.24705 36.5498 8.24705Z' />
      <path d='M17.06 32.0139C17.06 22.0966 31.2184 17.3119 31.2184 8.26446C31.2184 4.24532 29.2782 1.37451 28.4916 0.382775L28.1245 0L12.7425 15.311C12.7425 15.311 8.56494 19.4867 8.56494 23.2971C8.56494 27.8382 11.1694 30.448 11.1694 30.448L20.0839 39.6694C20.0839 39.6694 20.0839 39.6694 20.1014 39.652C18.7905 37.4772 17.06 35.6503 17.06 32.0139Z' />{' '}
    </svg>
  );
}
