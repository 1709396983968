export default function SendIcon({
  className,
}: {
  readonly className: string;
}) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <circle cx='10' cy='10' r='10' fill='#EB0573' />
      <path d='M6.44702 5.03192L15.9651 9.72199C16.1186 9.79765 16.1818 9.98346 16.1061 10.137C16.0759 10.1983 16.0264 10.2479 15.9651 10.278L6.44721 14.968C6.29367 15.0437 6.10785 14.9805 6.03219 14.827C5.99911 14.7598 5.99144 14.683 6.0106 14.6106L6.95361 11.0496C6.9848 10.9318 7.08208 10.8432 7.20225 10.8232L11.4677 10.112C11.5199 10.1033 11.5631 10.0688 11.5839 10.0219L11.5951 9.9846C11.6071 9.91223 11.567 9.84323 11.5021 9.81613L11.4677 9.80626L7.17817 9.09134C7.05795 9.0713 6.96064 8.98267 6.92948 8.86485L6.01039 5.38922C5.9666 5.22374 6.06526 5.05409 6.23074 5.01031C6.30308 4.99117 6.3799 4.99885 6.44702 5.03192Z' />
    </svg>
  );
}
