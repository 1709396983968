interface LogoProps {
  readonly color: string;
}

export default function LogoHomeIcon({ color }: LogoProps) {
  return (
    <div>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='102'
        height='100'
        viewBox='0 0 102 100'
        className={`${color}`}
      >
        <path d='M19.3586 57.7642C19.3586 48.2383 29.8026 37.799 29.8026 37.799L48.2871 19.3997C46.3207 17.1379 39.6785 10.6133 27.0495 10.6133C11.755 10.6133 -0.0437012 22.3575 -0.0437012 35.8416C-0.0437012 49.3258 9.35154 58.2427 9.35154 58.2427L23.9032 73.1622C22.0242 70.2914 19.3586 65.0282 19.3586 57.7642Z' />
        <path d='M91.3742 20.6176L72.8022 0.826416C72.8022 0.826416 80.4495 8.87339 80.4495 21.4876C80.4495 44.1061 45.0535 56.0678 45.0535 80.8612C45.0535 89.9956 49.4233 94.5628 52.7008 100C52.7008 100 74.2006 79.3388 91.5053 62.0704C113.136 40.5393 91.3742 20.6176 91.3742 20.6176Z' />
        <path d='M42.65 80.0348C42.65 55.2414 78.046 43.2797 78.046 20.6612C78.046 10.6133 73.1954 3.43628 71.229 0.956938L70.3113 0L31.8564 38.2775C31.8564 38.2775 21.4124 48.7168 21.4124 58.2427C21.4124 69.5955 27.9235 76.12 27.9235 76.12L50.2098 99.1736C50.2098 99.1736 50.2098 99.1735 50.2535 99.1301C46.9761 93.6929 42.65 89.1257 42.65 80.0348Z' />
      </svg>
    </div>
  );
}
