export default function BackgroundCircleIcon({
  className,
}: {
  readonly className: string;
}) {
  return (
    <svg viewBox='0 0 937.3 934.1' className={className}>
      <g>
        <path
          d='M468.6,934.1c-18.6,0-37.3-1.1-55.7-3.3l53.9-452.5c1.1,0.1,2.5,0.1,3.6,0l54.8,452.4C506.6,932.9,487.6,934.1,468.6,934.1
		z M303,903.9c-35.3-13.4-69-31-100.2-52.5l258.7-375.1c0.8,0.6,1.7,1,2.6,1.4L303,903.9z M635.2,903.6l-162-425.9
		c0.9-0.3,1.8-0.8,2.6-1.4l259.4,374.7C704.1,872.4,670.5,890.1,635.2,903.6z M118.4,776.8c-25.1-28.2-46.7-59.4-64.3-92.7
		l403-212.8c0.5,0.9,1.1,1.8,1.8,2.6L118.4,776.8z M819.5,776.2L478.4,474c0.7-0.8,1.3-1.7,1.8-2.6l403.4,212
		C866.1,716.7,844.5,747.9,819.5,776.2z M13.7,578.3C4.7,541.8,0.1,504.2,0,466.4l455.7-0.9c0,1.2,0.1,2.3,0.4,3.3L13.7,578.3z
		 M923.8,577.4L481.2,468.7c0.2-1,0.4-2.1,0.4-3.3v-1h455.7v1C937.3,503.2,932.7,540.9,923.8,577.4z M481.2,462.2
		c-0.2-0.9-0.6-1.8-1-2.7l402.5-213.6c17.7,33.4,31.4,69,40.6,105.7L481.2,462.2z M456.1,462.2L13.3,354.5
		c8.9-36.8,22.4-72.5,39.9-106L457,459.6C456.6,460.4,456.3,461.3,456.1,462.2z M458.9,457L117.2,155.5
		c24.9-28.2,53.2-53.4,84.1-74.9l260.2,374.1C460.5,455.3,459.6,456.1,458.9,457z M478.3,456.9c-0.8-0.8-1.6-1.6-2.5-2.2L733.7,79
		c31,21.3,59.5,46.4,84.6,74.4L478.3,456.9z M464.1,453.3l-163-425.6C336.4,14.2,373.4,5,411,0.4l55.7,452.3
		C465.8,452.8,464.9,453,464.1,453.3z M473.2,453.3c-0.9-0.3-1.8-0.5-2.7-0.7L523.3,0c37.6,4.4,74.7,13.4,110.1,26.7L473.2,453.3z'
        />
      </g>
    </svg>
  );
}
