export default function MoonIcon({
  className,
}: {
  readonly className: string;
}) {
  return (
    <svg
      className={className}
      width='23'
      height='26'
      viewBox='0 0 23 26'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M12.8335 25.1667C16.6891 25.1667 20.2104 23.4126 22.5433 20.5382C22.8884 20.113 22.5121 19.4918 21.9788 19.5934C15.9142 20.7484 10.3449 16.0985 10.3449 9.97651C10.3449 6.45005 12.2327 3.20723 15.3008 1.46118C15.7738 1.19204 15.6548 0.475 15.1174 0.375732C14.3641 0.236813 13.5996 0.166862 12.8335 0.166748C5.93364 0.166748 0.333496 5.75811 0.333496 12.6667C0.333496 19.5666 5.92485 25.1667 12.8335 25.1667Z' />
    </svg>
  );
}
