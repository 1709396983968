export default function GitHubIcon({
  className,
}: {
  readonly className: string;
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='21'
      height='20'
      viewBox='0 0 21 20'
      className={className}
    >
      <path d='M10.4668 0.000535672C7.72019 -0.0273746 5.07486 1.03638 3.11219 2.958C1.14953 4.87963 0.0301255 7.50189 0 10.2485C0.0105833 12.4191 0.708834 14.5305 1.99446 16.2794C3.28009 18.0283 5.08694 19.3248 7.15549 19.9826C7.67883 20.0778 7.86914 19.7638 7.86914 19.4878C7.86914 19.2119 7.86914 18.6029 7.86914 17.7465C4.95746 18.365 4.33897 16.3763 4.33897 16.3763C4.14514 15.752 3.73307 15.2184 3.1781 14.8729C2.22658 14.2449 3.25423 14.2544 3.25423 14.2544C3.58312 14.2984 3.8978 14.4163 4.17465 14.5992C4.4515 14.7821 4.68334 15.0253 4.85279 15.3106C5.14808 15.8227 5.6326 16.1983 6.20207 16.3567C6.77154 16.5151 7.38042 16.4435 7.89768 16.1575C7.95088 15.6364 8.18684 15.151 8.56375 14.7873C6.24202 14.5304 3.80611 13.655 3.80611 9.72515C3.78535 8.70005 4.16412 7.70707 4.86231 6.95621C4.54433 6.07611 4.58188 5.10673 4.96698 4.25387C4.96698 4.25387 5.8519 3.97792 7.82156 5.30055C9.53611 4.84366 11.3404 4.84366 13.055 5.30055C15.0532 3.97792 15.9095 4.25387 15.9095 4.25387C16.2946 5.10673 16.3322 6.07611 16.0142 6.95621C16.7286 7.6933 17.1279 8.67962 17.1275 9.70612C17.1275 13.6454 14.6726 14.5113 12.3699 14.7682C12.622 15.013 12.8168 15.3107 12.9402 15.6397C13.0636 15.9688 13.1125 16.3211 13.0835 16.6713V19.4783C13.0835 19.4783 13.2738 20.0778 13.7972 19.9731C15.8598 19.3112 17.6606 18.0144 18.9423 16.268C20.2239 14.5215 20.9209 12.4147 20.9336 10.2485C20.9035 7.50189 19.7841 4.87963 17.8214 2.958C15.8588 1.03638 13.2134 -0.0273746 10.4668 0.000535672Z' />
    </svg>
  );
}
