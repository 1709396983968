export default function SunIcon({ className }: { readonly className: string }) {
  return (
    <svg
      className={className}
      width='28'
      height='27'
      viewBox='0 0 28 27'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M13.9998 5.49984C14.3535 5.49984 14.6926 5.35936 14.9426 5.10931C15.1927 4.85926 15.3332 4.52013 15.3332 4.1665V1.49984C15.3332 1.14622 15.1927 0.807077 14.9426 0.557028C14.6926 0.30698 14.3535 0.166504 13.9998 0.166504C13.6462 0.166504 13.3071 0.30698 13.057 0.557028C12.807 0.807077 12.6665 1.14622 12.6665 1.49984V4.1665C12.6665 4.52013 12.807 4.85926 13.057 5.10931C13.3071 5.35936 13.6462 5.49984 13.9998 5.49984Z' />
      <path d='M26 12.1665H23.3333C22.9797 12.1665 22.6406 12.307 22.3905 12.557C22.1405 12.8071 22 13.1462 22 13.4998C22 13.8535 22.1405 14.1926 22.3905 14.4426C22.6406 14.6927 22.9797 14.8332 23.3333 14.8332H26C26.3536 14.8332 26.6928 14.6927 26.9428 14.4426C27.1929 14.1926 27.3333 13.8535 27.3333 13.4998C27.3333 13.1462 27.1929 12.8071 26.9428 12.557C26.6928 12.307 26.3536 12.1665 26 12.1665Z' />
      <path d='M5.99984 13.4998C5.99984 13.1462 5.85936 12.8071 5.60931 12.557C5.35926 12.307 5.02013 12.1665 4.6665 12.1665H1.99984C1.64622 12.1665 1.30708 12.307 1.05703 12.557C0.80698 12.8071 0.666504 13.1462 0.666504 13.4998C0.666504 13.8535 0.80698 14.1926 1.05703 14.4426C1.30708 14.6927 1.64622 14.8332 1.99984 14.8332H4.6665C5.02013 14.8332 5.35926 14.6927 5.60931 14.4426C5.85936 14.1926 5.99984 13.8535 5.99984 13.4998Z' />
      <path d='M6.29348 4.1666C6.03357 3.92083 5.68667 3.78838 5.3291 3.79838C4.97153 3.80838 4.63258 3.96002 4.38681 4.21993C4.14104 4.47984 4.00859 4.82674 4.01859 5.18431C4.0286 5.54188 4.18023 5.88083 4.44014 6.1266L6.36014 7.97993C6.48899 8.10434 6.64158 8.20152 6.80881 8.26566C6.97604 8.32981 7.15448 8.3596 7.33348 8.35326C7.51308 8.35258 7.6907 8.31561 7.85567 8.24458C8.02063 8.17356 8.16955 8.06993 8.29348 7.93993C8.54181 7.69011 8.6812 7.35218 8.6812 6.99993C8.6812 6.64768 8.54181 6.30975 8.29348 6.05993L6.29348 4.1666Z' />
      <path d='M20.6662 8.35324C21.0095 8.35187 21.339 8.21815 21.5862 7.97991L23.5062 6.12657C23.7404 5.88184 23.8727 5.55713 23.8762 5.2184C23.8796 4.87966 23.7541 4.5523 23.5249 4.3028C23.2958 4.0533 22.9803 3.90037 22.6425 3.87506C22.3047 3.84976 21.9699 3.95399 21.7062 4.16657L19.7862 6.05991C19.5378 6.30972 19.3984 6.64766 19.3984 6.99991C19.3984 7.35215 19.5378 7.69009 19.7862 7.93991C20.017 8.18353 20.3313 8.33113 20.6662 8.35324Z' />
      <path d='M13.9998 21.4998C13.6462 21.4998 13.3071 21.6402 13.057 21.8903C12.807 22.1403 12.6665 22.4795 12.6665 22.8331V25.4998C12.6665 25.8534 12.807 26.1925 13.057 26.4426C13.3071 26.6926 13.6462 26.8331 13.9998 26.8331C14.3535 26.8331 14.6926 26.6926 14.9426 26.4426C15.1927 26.1925 15.3332 25.8534 15.3332 25.4998V22.8331C15.3332 22.4795 15.1927 22.1403 14.9426 21.8903C14.6926 21.6402 14.3535 21.4998 13.9998 21.4998Z' />
      <path d='M21.6399 19.0198C21.3852 18.774 21.0434 18.6395 20.6896 18.6457C20.3358 18.652 19.999 18.7985 19.7532 19.0531C19.5074 19.3077 19.3729 19.6495 19.3791 20.0034C19.3854 20.3572 19.5319 20.694 19.7865 20.9398L21.7065 22.8331C21.9537 23.0714 22.2832 23.2051 22.6265 23.2065C22.8052 23.2075 22.9822 23.1726 23.1471 23.1039C23.312 23.0352 23.4615 22.934 23.5865 22.8065C23.7115 22.6825 23.8107 22.535 23.8784 22.3726C23.9461 22.2101 23.9809 22.0358 23.9809 21.8598C23.9809 21.6838 23.9461 21.5095 23.8784 21.347C23.8107 21.1845 23.7115 21.0371 23.5865 20.9131L21.6399 19.0198Z' />
      <path d='M6.35981 19.0198L4.43981 20.8732C4.31484 20.9971 4.21564 21.1446 4.14795 21.3071C4.08026 21.4695 4.04541 21.6438 4.04541 21.8198C4.04541 21.9958 4.08026 22.1701 4.14795 22.3326C4.21564 22.4951 4.31484 22.6425 4.43981 22.7665C4.56486 22.8941 4.71429 22.9952 4.8792 23.064C5.04411 23.1327 5.22115 23.1675 5.39981 23.1665C5.72849 23.1693 6.04663 23.0506 6.29314 22.8332L8.21314 20.9798C8.46775 20.7341 8.6143 20.3972 8.62055 20.0434C8.6268 19.6896 8.49224 19.3478 8.24647 19.0932C8.00071 18.8386 7.66386 18.692 7.31004 18.6858C6.95623 18.6795 6.61442 18.8141 6.35981 19.0598V19.0198Z' />
      <path d='M13.9998 8.1665C12.945 8.1665 11.9139 8.4793 11.0368 9.06533C10.1597 9.65137 9.47615 10.4843 9.07248 11.4589C8.66881 12.4334 8.5632 13.5058 8.76898 14.5403C8.97477 15.5749 9.48272 16.5252 10.2286 17.2711C10.9745 18.017 11.9248 18.5249 12.9594 18.7307C13.9939 18.9365 15.0663 18.8309 16.0408 18.4272C17.0154 18.0235 17.8483 17.3399 18.4343 16.4629C19.0204 15.5858 19.3332 14.5547 19.3332 13.4998C19.3332 12.0853 18.7713 10.7288 17.7711 9.7286C16.7709 8.72841 15.4143 8.1665 13.9998 8.1665Z' />
    </svg>
  );
}
