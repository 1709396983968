export default function EyeOpenIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='15'
      viewBox='0 0 190 127'
      className='fill-light'
    >
      <path d='M188.125 58.2921C170.305 23.5238 135.028 0 94.6341 0C54.2404 0 18.953 23.5403 1.14332 58.2954C0.391639 59.7823 0 61.425 0 63.0911C0 64.7572 0.391639 66.4 1.14332 67.8869C18.9628 102.655 54.2404 126.179 94.6341 126.179C135.028 126.179 170.315 102.639 188.125 67.8836C188.877 66.3967 189.268 64.754 189.268 63.0879C189.268 61.4217 188.877 59.779 188.125 58.2921ZM94.6341 110.407C85.2756 110.407 76.1273 107.632 68.3461 102.432C60.5648 97.233 54.5001 89.843 50.9187 81.197C47.3374 72.5509 46.4004 63.037 48.2261 53.8584C50.0519 44.6798 54.5584 36.2487 61.1758 29.6312C67.7932 23.0138 76.2243 18.5073 85.403 16.6816C94.5816 14.8558 104.095 15.7929 112.742 19.3742C121.388 22.9555 128.778 29.0203 133.977 36.8015C139.176 44.5828 141.951 53.7311 141.951 63.0895C141.954 69.3041 140.732 75.4584 138.356 81.2006C135.979 86.9427 132.493 92.1601 128.099 96.5545C123.705 100.949 118.487 104.434 112.745 106.811C107.003 109.188 100.849 110.41 94.6341 110.407ZM94.6341 31.5448C91.8185 31.5841 89.021 32.003 86.3174 32.7901C88.546 35.8186 89.6154 39.5456 89.3318 43.295C89.0482 47.0444 87.4302 50.568 84.7714 53.2268C82.1126 55.8856 78.589 57.5036 74.8396 57.7872C71.0901 58.0709 67.3632 57.0014 64.3347 54.7729C62.6101 61.1265 62.9214 67.8609 65.2248 74.0283C67.5281 80.1957 71.7075 85.4855 77.1747 89.1532C82.642 92.8209 89.1217 94.6818 95.7019 94.4739C102.282 94.266 108.631 91.9999 113.856 87.9944C119.081 83.989 122.918 78.4459 124.828 72.1454C126.737 65.8449 126.623 59.1043 124.501 52.8722C122.379 46.6401 118.356 41.2304 112.998 37.4045C107.64 33.5787 101.218 31.5293 94.6341 31.5448Z' />
    </svg>
  );
}
